<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!firstRun">
      <b-col>
        <b-card class="mb-3" v-if="permissionReadAnalytics">
            <button-analytics-studio
                :dataProp="news"
            />
        </b-card>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            v-bind:propItem="item"
            v-bind:key="item.id"
            v-bind:username="user.username"
            :tagsProp=tags
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionReadTagsPanel="permissionReadTagsPanel"
            />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row v-show="!loadingNews" class="text-center mb-4" align-h="center">
        <b-col>
            <b-button variant="primary" size="sm" v-on:click="bySector">Load more</b-button>
        </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'
import ButtonAnalyticsStudio from '@/components/ButtonAnalyticsStudio'

export default {
  beforeDestroy () {
  },
  components: {
    ButtonAnalyticsStudio,
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'news category', action: 'open news category', payload: { category: this.$route.params.category } })
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.date = moment()
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.bySector()
  },
  data () {
    return {
      firstRun: true,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      loadingNews: false,
      news: [],
      permissionAddArticle: false,
      permissionArticlesWip: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      tags: [],
      limit: 20,
      offset: 0,
      page: 0
    }
  },
  methods: {
    bySector: async function () {
      this.$logger.debug('bySector started')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/news/category/${this.$route.params.category}/limit/${this.limit}/offset/${this.offset}`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('bySector response: ', response)
        let news = response
        for (let i = 0, len = news.length; i < len; i++) {
          this.news.push(news[i])
        }
        this.$logger.debug(this.news)
        this.$localDb.setItem('analyticsData', this.news)
        this.page = this.page + 1
        this.offset = this.page * this.limit
        this.firstRun = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('bySecotr loading error' + e)
      }
    }
  }
}
</script>

<style>
</style>
