<template>
    <b-button variant="outline-primary" @click="addData">Analytics<span class="badge badge-warning ml-1">BETA</span></b-button>
</template>

<script>
import Config from '../config/config'
export default {
  name: 'ButtonAnalyticsStudio',
  props: {
    dataProp: Array
  },
  data () {
    return {
    }
  },
  methods: {
    addData: function () {
      this.$localDb.setItem('analyticsData', this.dataProp).then(function (value) {
        const url = Config.BASE_URL + '/analytics-studio/index.html'
        window.open(url)
      })
    }
  }
}
</script>

<style>
</style>
